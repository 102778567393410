import React from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import SexyMeme from "../components/sexyMeme";
// import SexyMeme from "../components/sexyMeme";

export default function Home({data}) {

  const { allMarkdownRemark: { edges }} = data;
  const [{node: meme}] = edges;
  const { frontmatter: { title } } = meme;
  return (
    <Layout title={title}>
      <SexyMeme meme={meme} />
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </Layout>
  );
}

export const query = graphql`
  
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC}, limit: 1 ) {
      edges {
        node {
            ...Meme
          }
      }
    }
  }
`;